import React from 'react';
import './Bienestar.scss';
import Topbar from '../../components/topbar/Topbar';
import Navbar from '../../components/navbar/Navbar';
import Button from '../../components/button/Button';

function Bienestar() {
  return (
    <>
    <Topbar
      user='Fundación María Fortaleza'
    />
  <div className='bienestar'>
        <h2>Bienestar</h2>
      <div className="bienestar__container">
      <p>Sabemos que tu trabajo puede ser muy desgastante, es por eso que queremos brindarte un espacio para que te expreses libremente.</p>
      <form  className="bienestar__form">
        <h3>¿Como te sentiste hoy?</h3>
          <select name="select" >
              <option hidden defaultValue>Elige el servicio</option>
              <option value="😊 Pura felicidad al 100%">😊 Pura felicidad al 100%</option>
              <option value="🤓 Un dia muy productivo">🤓 Un dia muy productivo</option>
              <option value="😫 Hoy me cansé mucho">😫 Hoy me cansé mucho</option>
              <option value="😟 La ansiedad y la duda me agarraron">😟 La ansiedad y la duda me agarraron</option>
              <option value="😥  Hoy la tristeza me acompañó">😥 Hoy la tristeza me acompañó</option>
              <option value="😔 Hoy la suerte no estuvo de mi lado">😔 Hoy la suerte no estuvo de mi lado</option>
          </select> 
          <div className="bienestar__textArea">
            <h3>¿Hay algo que quieras decir?</h3>
            <p>Este es un espacio parta que te expreses libremente.</p>
            <textarea name="" id="" cols="30" rows="10" placeholder='ahshsgdhdheheh##51412'></textarea>
          </div>  
          <div className="bienestar__btn">
            <Button
                title='Enviar'
            />
          </div>
      </form>    
      </div>
    </div>
    <Navbar/>
    </>
  )
}

export default Bienestar;