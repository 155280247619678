import React, { useState } from 'react';
import '../../Form.scss';
import { useNavigate } from 'react-router-dom';
import Topbar from '../../../../components/topbar/Topbar';
import Input from '../../../../components/input/Input';
import Navbar from '../../../../components/navbar/Navbar';
import Button from '../../../../components/button/Button';
import Swal from 'sweetalert2';
import { db } from '../../../../firebase/firebaseConfig';
import { collection, addDoc } from 'firebase/firestore';
import { useUserAuth } from '../../../../context/UserAuthContext';


function Form15() {

  const [servicio, setServicio] = useState('');
  const [area, setArea] = useState('');
  const [dotacion1, setDotacion1] = useState('');
  const [dotacion2, setDotacion2] = useState('');
  const [dotacion3, setDotacion3] = useState('');
  const [limpieza1, setLimpieza1] = useState('');
  const [limpieza2, setLimpieza2] = useState('');
  const [limpieza3, setLimpieza3] = useState('');
  const [limpieza4, setLimpieza4] = useState('');
  const [limpieza5, setLimpieza5] = useState('');
  const [limpieza6, setLimpieza6] = useState('');
  const [residuos1, setResiduos1] = useState('');
  const [residuos2, setResiduos2] = useState('');
  const [observacion, setObservacion] = useState('');

  const navigate = useNavigate();
  const date = new Date();
  const { user } = useUserAuth();
  const formCollection = collection(db, 'Registro de limpieza y desinfección de áreas');

  const handleSubmit = async (e) => {
    e.preventDefault(e);
    await addDoc(formCollection, {
      date: date,
      servicio: servicio,
      area: area,
      dotacion1: dotacion1,
      dotacion2: dotacion2,
      dotacion3: dotacion3,
      limpieza1: limpieza1,
      limpieza2: limpieza2,
      limpieza3: limpieza3,
      limpieza4: limpieza4,
      limpieza5: limpieza5,
      limpieza6: limpieza6,
      residuos1: residuos1,
      residuos2: residuos2,
      observacion: observacion
    })
    .then(()=> {
      Swal.fire(
        '¡Buen trabajo!',
        'El forumlario ha sido subido exitosamente',
        'success'
      )
    })
    .then(()=>navigate('/formatos-limpieza'))
}
   
  return (
   <>
   <Topbar/>
   <div className="form">
    <h2>Registro de limpieza y desinfección de áreas</h2>
    <div className="form__container">
        <form onSubmit={handleSubmit}>
            <br />
            <Input
                label='Servicio'
                type='text'
                onChange={(e)=>setServicio(e.target.value)}
            />
            <Input
                label='Área'
                type='text'
                onChange={(e)=>setArea(e.target.value)}
            />
            <h3>Dotación</h3>
            <br />
            <p>Jabón Liquido</p>
          <select name="select" onChange={(e)=>setDotacion1(e.target.value)}>
              <option hidden defaultValue>Elige la opción</option>
              <option value="Cumple">Cumple</option>
              <option value="No cumple">No cumple</option>
              <option value="No aplica">No aplica</option>
          </select> 
            <p>Toallas de mano</p>
          <select name="select" onChange={(e)=>setDotacion2(e.target.value)} >
              <option hidden defaultValue>Elige la opción</option>
              <option value="Cumple">Cumple</option>
              <option value="No cumple">No cumple</option>
              <option value="No aplica">No aplica</option>
          </select> 
            <p>Gel antibacterial</p>
          <select name="select" onChange={(e)=>setDotacion3(e.target.value)}>
              <option hidden defaultValue>Elige la opción</option>
              <option value="Cumple">Cumple</option>
              <option value="No cumple">No cumple</option>
              <option value="No aplica">No aplica</option>
          </select> 
          <h3>Limpieza y desinfección</h3>
            <br />
            <p>Piso</p>
          <select name="select" onChange={(e)=>setLimpieza1(e.target.value)}>
              <option hidden defaultValue>Elige la opción</option>
              <option value="Cumple">Cumple</option>
              <option value="No cumple">No cumple</option>
              <option value="No aplica">No aplica</option>
          </select> 
            <p>Techo</p>
          <select name="select" onChange={(e)=>setLimpieza2(e.target.value)}>
              <option hidden defaultValue>Elige la opción</option>
              <option value="Cumple">Cumple</option>
              <option value="No cumple">No cumple</option>
              <option value="No aplica">No aplica</option>
          </select> 
            <p>Paredes</p>
          <select name="select" onChange={(e)=>setLimpieza3(e.target.value)}>
              <option hidden defaultValue>Elige la opción</option>
              <option value="Cumple">Cumple</option>
              <option value="No cumple">No cumple</option>
              <option value="No aplica">No aplica</option>
          </select> 
            <p>Mobiliario</p>
          <select name="select" onChange={(e)=>setLimpieza4(e.target.value)}>
              <option hidden defaultValue>Elige la opción</option>
              <option value="Cumple">Cumple</option>
              <option value="No cumple">No cumple</option>
              <option value="No aplica">No aplica</option>
          </select> 
            <p>Puertas</p>
          <select name="select" onChange={(e)=>setLimpieza5(e.target.value)}>
              <option hidden defaultValue>Elige la opción</option>
              <option value="Cumple">Cumple</option>
              <option value="No cumple">No cumple</option>
              <option value="No aplica">No aplica</option>
          </select> 
            <p>Vidrios</p>
          <select name="select" onChange={(e)=>setLimpieza6(e.target.value)}>
              <option hidden defaultValue>Elige la opción</option>
              <option value="Cumple">Cumple</option>
              <option value="No cumple">No cumple</option>
              <option value="No aplica">No aplica</option>
          </select> 
          <h3>Residuos</h3>
          <br />
          <p>Recolección de residuos</p>
          <select name="select" onChange={(e)=>setResiduos1(e.target.value)}>
              <option hidden defaultValue>Elige la opción</option>
              <option value="Cumple">Cumple</option>
              <option value="No cumple">No cumple</option>
              <option value="No aplica">No aplica</option>
          </select> 
          <p>Lavado de canecas</p>
          <select name="select" onChange={(e)=>setResiduos2(e.target.value)}>
              <option hidden defaultValue>Elige la opción</option>
              <option value="Cumple">Cumple</option>
              <option value="No cumple">No cumple</option>
              <option value="No aplica">No aplica</option>
          </select> 
          <Input
            label='Observaciones' 
            type="text" 
            onChange={(e)=>setObservacion(e.target.value)}
          />     
        <div className="form__btn">
            <Button
                title='Enviar'
            />
        </div>
        </form>
    </div>
   </div>
   <Navbar/>
   </>
  )
}

export default Form15;