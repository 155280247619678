import React from 'react';
import './Feed.scss';
import { Link } from 'react-router-dom';
import Topbar from '../../components/topbar/Topbar';
import Card from '../../components/card/Card';
import Navbar from '../../components/navbar/Navbar';
import { MdOutlineChecklistRtl, MdOutlineAssignment } from "react-icons/md";

function Feed() {

  const data = [
    {
     id: '1',
     path: '/forms',
     icon: <MdOutlineChecklistRtl/>,
     title: 'Formatos',
     description: 'Listas de chequeo',
    },
    {
     id: '2',
     path: '/documentos',
     icon: <MdOutlineAssignment/>,
     title: 'Documentos',
     description: 'Guías y protocolos',
    }
  ]
  return (
    <>
    <Topbar
      user='Fundación María Fortaleza'
    />
    <div className='feed'>
        <h2>Inicio</h2>
      <div className="feed__container">
          <h3>Elige la actividad</h3>
          <div className="feed__cards">
            {
              data.map( item => 
                <Link to={item.path} style={{textDecoration: 'none'}} >
                  <Card
                      id={item.id}
                      icon={item.icon}
                      title={item.title}
                      description={item.description}
                    />
                </Link>
                 
                )
            }
          </div>
      </div>
    </div>
    <Navbar/>
    </>
  )
}

export default Feed