import React, { useState } from 'react';
import '../../Form.scss';
import Topbar from '../../../../components/topbar/Topbar';
import Input from '../../../../components/input/Input';
import Navbar from '../../../../components/navbar/Navbar';
import Button from '../../../../components/button/Button';
import Swal from 'sweetalert2';
import { db } from '../../../../firebase/firebaseConfig';
import { collection, addDoc } from 'firebase/firestore';
import { useUserAuth } from '../../../../context/UserAuthContext';
import { useNavigate } from 'react-router-dom';


function Form4() {

    const [area, setArea] = useState('');
    const [turno, setTurno] = useState('');
    const [temperatura, setTemperatura] = useState('');
    const [humedad, setHumedad] = useState('');
    const [frio, setFrio] = useState('');

    const navigate = useNavigate();
    const date = new Date();
    const { user } = useUserAuth();
    const formCollection = collection(db, 'Formato de condiciones ambientales');


    const handleSubmit = async (e) => {
        e.preventDefault(e);
        await addDoc(formCollection, { 
            date: date,
            area: area,
            turno: turno,
            temperatura: temperatura,
            humedad: humedad,
            frio: frio
        })
        .then(()=> {
            Swal.fire(
                '¡Buen trabajo!',
                'El forumlario ha sido subido exitosamente',
                'success'
            );
        })
        .then(() => navigate('/formatos-limpieza'))
    }
   
  return (
   <>
   <Topbar/>
   <div className="form">
    <h2>Formato de condiciones ambientales</h2>
    <div className="form__container">
        <p>Control de temperatura, humedad relativa y cadena de frio.</p>
        <form onSubmit={handleSubmit}>
            <br />
            <Input
                label='Área'
                onChange={(e)=>setArea(e.target.value)}
            />
            <p>Turno</p>
          <select name="select"    onChange={(e)=>setTurno(e.target.value)}>
              <option hidden defaultValue>Elige la opción</option>
                <option value="Mañana">Mañana</option>
                <option value="Tarde">Tarde</option>
                <option value="Noche">Noche</option>
                <option value="Mañana">Mañana</option>
                <option value="Tarde">Tarde</option>
                <option value="Noche">Noche</option>
            
          </select> 
            <br />
            <br />
        <p>Temperatura</p>
        <select name="select" onChange={(e)=>setTemperatura(e.target.value)}>
                <option hidden defaultValue>Elige la opción</option>
                <option value="10 ºC">10 ºC</option>
                <option value="12 ºC ">12 ºC</option>
                <option value="14 ºC">14 ºC</option>
                <option value="16 ºC ">16 ºC</option>
                <option value="18 ºC">18 ºC</option>
                <option value="20 ºC">20 ºC</option>
                <option value="22 ºC">22 ºC</option>
                <option value="24 ºC">24 ºC</option>
                <option value="26 ºC">26 ºC</option>
                <option value="2 ºC">28 ºC</option>
                <option value="30 ºC">30 ºC</option>
                <option value="32 ºC">32 ºC</option>
                <option value="34 ºC">34 ºC</option>
        </select>   
        <p>Humedad relativa</p>
        <select name="select" onChange={(e)=>setHumedad(e.target.value)}>
            <optgroup label='Humedo'>
                <option hidden defaultValue>Elige la opción</option>
                <option value="72%">72%</option>
                <option value="74%">7%4</option>
                <option value="76%">76%</option>
                <option value="78%">78%</option>
            </optgroup>
            <optgroup label='Normal'>
                <option hidden defaultValue>Elige la opción</option>
                <option value="52%">52%</option>
                <option value="54%">54%</option>
                <option value="56%">56%</option>
                <option value="58%">58%</option>
                <option value="60%">60%</option>
                <option value="62%">62%</option>
                <option value="64%">64%</option>
                <option value="66%">66%</option>
                <option value="68%">68%</option>
                <option value="70%">70%</option>
            </optgroup>
            <optgroup label='Seco'>
                <option hidden defaultValue>Elige la opción</option>
                <option value="46%">46%</option>
                <option value="48%">48%</option>
                <option value="50%">50%</option>
            </optgroup>
        </select>  
        <p>Cadena de frio</p>
        <select name="select" onChange={(e)=>setFrio(e.target.value)}>
                <option hidden defaultValue>Elige la opción</option>
                <option value="-1 ºC">-1 ºC</option>
                <option value="0 ºC ">0 ºC</option>
                <option value="1 ºC">1 ºC</option>
                <option value="2 ºC ">2 ºC</option>
                <option value="3 ºC">3 ºC</option>
                <option value="4 ºC">4 ºC</option>
                <option value="5 ºC">5 ºC</option>
                <option value="6 ºC">6 ºC</option>
                <option value="7 ºC">7 ºC</option>
                <option value="8 ºC">8 ºC</option>
                <option value="9 ºC">9 ºC</option>
                <option value="10 ºC">10 ºC</option>
                <option value="11 ºC">11 ºC</option>
        </select>  
        <div className="form__btn">
            <Button
                title='Enviar'
            />
        </div>
        </form>
    </div>
   </div>
   <Navbar/>
   </>
  )
}

export default Form4;