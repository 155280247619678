import React from 'react';
import './Documentos.scss';
import { Link } from 'react-router-dom';
import Topbar from '../../components/topbar/Topbar';
import OptionCard from '../../components/optionCard/OptionCard';
import Navbar from '../../components/navbar/Navbar';
import { MdReportGmailerrorred, MdOutlineCleaningServices, MdOutlineMedicalServices, MdStorefront, MdDeleteOutline } from "react-icons/md";

function Forms() {

  const data = [
    {
        id: '1',
        path: 'https://docs.google.com/document/d/12N7XxtnbVs3MSCIrcZlcblcv1181tw30/edit?usp=sharing&ouid=111618067534118613324&rtpof=true&sd=true',
        title: 'Protocolo lavado de manos',
    },
    {
        id: '2',
        path: 'https://docs.google.com/document/d/1W9iieL1ckMlMEd047sw3hirfF5_AWB7c/edit?usp=sharing&ouid=111618067534118613324&rtpof=true&sd=true',
        title: 'Procedimiento manejo de derrames de riesgo biologico',
    },
    {
        id: '3',
        path: 'https://docs.google.com/document/d/1DcHLku_DGiwVjFJ04_Z3l1177eRGM3E1/edit?usp=sharing&ouid=111618067534118613324&rtpof=true&sd=true',
        title: 'Protocolo para manejo de medicamentos lasa',
    },
    {
        id: '4',
        path: 'https://docs.google.com/document/d/1uKbVBg5YYzO7NBTetIZ9-BoCperDZhCE/edit?usp=sharing&ouid=111618067534118613324&rtpof=true&sd=true',
        title: 'Protocolo identificación correcta del paciente',
    },
    {
        id: '5',
        path: 'https://docs.google.com/document/d/1KjKhQ-otF7jsw1KS94VIkG_zoEFb6OZB/edit?usp=sharing&ouid=111618067534118613324&rtpof=true&sd=true',
        title: 'Protocolo prevencion de infecciones IAA',
    },
    {
        id: '6',
        path: 'https://docs.google.com/document/d/1uCiLDbo9vJGwwp2RjKKkAApTBryMXuOg/edit?usp=sharing&ouid=111618067534118613324&rtpof=true&sd=true',
        title: 'Protocolo control de plagas',
    },
    {
        id: '7',
        path: 'https://docs.google.com/document/d/1Y3YfL8x8cnG2ePJduSgIFNFEQeKM-4QT/edit?usp=sharing&ouid=111618067534118613324&rtpof=true&sd=true',
        title: 'Protocolo de referencia y contrarreferencia',
    },
    {
        id: '8',
        path: 'https://docs.google.com/document/d/1M_QuQflVA-4Kj7BpjnyqJGLH7FjiIRHp/edit?usp=sharing&ouid=111618067534118613324&rtpof=true&sd=true',
        title: 'Programa de limpieza y desinfeccion',
    },
    {
        id: '9',
        path: 'https://docs.google.com/document/d/1k0OR8t8WFtLNQkJTDEdCNvEL7nGiAqbl/edit?usp=sharing&ouid=111618067534118613324&rtpof=true&sd=true',
        title: 'Procedimiento lavado de tanques',
    },


  ]
  return (
    <>
    <Topbar
      user='Fundación María Fortaleza'
    />
    <div className='feed'>
        <h2>Documentos</h2>
      <div className="feed__container">
          <h3>Guías y protocólos</h3>
          <div className="feed__cards">
            {
              data.map( item => 
                <a href={item.path} target='_blank' style={{textDecoration: 'none'}} >
                  <OptionCard
                      id={item.id}
                      icon={item.icon}
                      title={item.title}
                      description={item.description}
                    />
                </a>
                 
                )
            }
          </div>
      </div>
    </div>
    <Navbar/>
    </>
  )
}

export default Forms;