import React from 'react';
import '../../Form.scss';
import { useNavigate } from 'react-router-dom';
import Topbar from '../../../../components/topbar/Topbar';
import Input from '../../../../components/input/Input';
import Button from '../../../../components/button/Button';
import Navbar from '../../../../components/navbar/Navbar';
import Swal from 'sweetalert2';

function Form18() {

    const handleSubmit = (e) => {
        e.preventDefault(e);
        alert('¡Los datos han sido subidos exitosamente!')
    }

  return (
    <>
    <Topbar/>
    <div className="form">
        <h2>Auditoría interna de fórmulas médicas</h2>
        <div className="form__container">
          <form onSubmit={handleSubmit}>
          <br />
          <p>Lugar y fecha de la prescripción.</p>
          <select name="select" >
              <option hidden defaultValue>Elige la opción</option>
              <option value="Cumple">Cumple</option>
              <option value="No cumple">No cumple</option>
              <option value="No aplica">No aplica</option>
          </select> 
          <p>Nombre del paciente y documento de identificación.</p>
          <select name="select" >
              <option hidden defaultValue>Elige la opción</option>
              <option value="Cumple">Cumple</option>
              <option value="No cumple">No cumple</option>
              <option value="No aplica">No aplica</option>
          </select> 
          <p>Número de la historia clínica.</p>
          <select name="select" >
              <option hidden defaultValue>Elige la opción</option>
              <option value="Cumple">Cumple</option>
              <option value="No cumple">No cumple</option>
              <option value="No aplica">No aplica</option>
          </select> 
          <p>Tipo de usuario (contributivo, subsidiado, particular, otro).</p>
          <select name="select" >
              <option hidden defaultValue>Elige la opción</option>
              <option value="Cumple">Cumple</option>
              <option value="No cumple">No cumple</option>
              <option value="No aplica">No aplica</option>
          </select> 
          <p>Nombre del medicamento expresado en la Denominación Común Internacional (Nombre genérico). </p>
          <select name="select" >
              <option hidden defaultValue>Elige la opción</option>
              <option value="Cumple">Cumple</option>
              <option value="No cumple">No cumple</option>
              <option value="No aplica">No aplica</option>
          </select> 
          <p>Concentración y forma farmacéutica.</p>
          <select name="select" >
              <option hidden defaultValue>Elige la opción</option>
              <option value="Cumple">Cumple</option>
              <option value="No cumple">No cumple</option>
              <option value="No aplica">No aplica</option>
          </select> 
          <p>Vía de administración.</p>
          <select name="select" >
              <option hidden defaultValue>Elige la opción</option>
              <option value="Cumple">Cumple</option>
              <option value="No cumple">No cumple</option>
              <option value="No aplica">No aplica</option>
          </select> 
          <p>Dosis y frecuencia de administración.</p>
          <select name="select" >
              <option hidden defaultValue>Elige la opción</option>
              <option value="Cumple">Cumple</option>
              <option value="No cumple">No cumple</option>
              <option value="No aplica">No aplica</option>
          </select> 
          <p>Período de duración del tratamiento.</p>
          <select name="select" >
              <option hidden defaultValue>Elige la opción</option>
              <option value="Cumple">Cumple</option>
              <option value="No cumple">No cumple</option>
              <option value="No aplica">No aplica</option>
          </select> 
          <p>Cantidad total de unidades farmacéuticas requeridas para el tratamiento, en números y letras.</p>
          <select name="select" >
              <option hidden defaultValue>Elige la opción</option>
              <option value="Cumple">Cumple</option>
              <option value="No cumple">No cumple</option>
              <option value="No aplica">No aplica</option>
          </select> 
          <p>Indicaciones que a su juicio considere el prescriptor.</p>
          <select name="select" >
              <option hidden defaultValue>Elige la opción</option>
              <option value="Cumple">Cumple</option>
              <option value="No cumple">No cumple</option>
              <option value="No aplica">No aplica</option>
          </select> 
          <p>Vigencia de la prescripción. </p>
          <select name="select" >
              <option hidden defaultValue>Elige la opción</option>
              <option value="Cumple">Cumple</option>
              <option value="No cumple">No cumple</option>
              <option value="No aplica">No aplica</option>
          </select> 
          <p>Nombre y firma del prescriptor con su respectivo número de registro profesional.</p>
          <select name="select" >
              <option hidden defaultValue>Elige la opción</option>
              <option value="Cumple">Cumple</option>
              <option value="No cumple">No cumple</option>
              <option value="No aplica">No aplica</option>
          </select> 
          <p>Sello de entregado por Servicio Farmacéutico</p>
          <select name="select" >
              <option hidden defaultValue>Elige la opción</option>
              <option value="Cumple">Cumple</option>
              <option value="No cumple">No cumple</option>
              <option value="No aplica">No aplica</option>
          </select> 
          <Input
            label='Observaciones'
          />
          <div className="form__btn">
            <Button
              title='Enviar'
            />
          </div>
          </form>
          
        </div>
    </div>
    <Navbar/>
    </>
  )
}

export default Form18;