import React from 'react';
import '../Form.scss';
import { Link } from 'react-router-dom';
import Topbar from '../../../components/topbar/Topbar';
import OptionCard from '../../../components/optionCard/OptionCard';
import Navbar from '../../../components/navbar/Navbar';
import { MdReportGmailerrorred, MdOutlineCleaningServices, MdOutlineMedicalServices, MdStorefront, MdDeleteOutline } from "react-icons/md";

function Residuos() {

  const data = [
    {
        id: '1',
        path: '/formatos-residuos-formato7',
        icon: <MdStorefront/>,
        title: 'Fuentes de generación y clases de residuos',
        description: 'Estrategia multimodal',
    },
    {
        id: '1',
        path: '/formatos-residuos-formato8',
        icon: <MdStorefront/>,
        title: 'Gestión de residuos hospitalarios',
        description: 'Estrategia multimodal',
    }
  ]
  return (
    <>
    <Topbar
      user='Fundación María Fortaleza'
    />
    <div className='feed'>
    <Link to='/forms' style={{textDecoration: 'none', color: '#6100DD'}}><h2>Formatos</h2></Link>      <div className="feed__container">
          <h3>Residuos</h3>
          <div className="feed__cards">
            {
              data.map( item => 
                <Link to={item.path} style={{textDecoration: 'none'}} >
                  <OptionCard
                      id={item.id}
                      icon={item.icon}
                      title={item.title}
                      description={item.description}
                    />
                </Link>
                )
            }
          </div>
      </div>
    </div>
    <Navbar/>
    </>
  )
}

export default Residuos;