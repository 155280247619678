import React from 'react';
import '../Form.scss';
import { Link } from 'react-router-dom';
import Topbar from '../../../components/topbar/Topbar';
import OptionCard from '../../../components/optionCard/OptionCard';
import Navbar from '../../../components/navbar/Navbar';
import { MdReportGmailerrorred, MdOutlineCleaningServices, MdOutlineMedicalServices, MdStorefront, MdDeleteOutline } from "react-icons/md";

function Limpieza() {

  const data = [
    {
        id: '1',
        path: '/formatos-limpieza-formato15',
        title: 'Limpieza y desinfección de áreas',
    },
    {
        id: '2',
        path: '/formatos-limpieza-formato16',
        title: 'Limpieza y desinfección de baños',
    },
    {
        id: '3',
        path: '/formatos-limpieza-formato4',
        title: 'Control de condiciones ambientales',
    },
    {
        id: '4',
        path: '/formatos-limpieza-higiene-manos',
        title: 'Higiene de manos',
    },
    {
        id: '4',
        title: 'Ficha técnica de reuso',
        onClick: ()=>alert('Este formulario no está disponible aún')
    },
    {
        id: '5',
        title: 'Limpieza servicio farmacéutico',
        onClick: ()=>alert('Este formulario no está disponible aún')
    },
    {
        id: '6',
        title: 'Limpieza y desinfección de neveras ... ',
        onClick: ()=>alert('Este formulario no está disponible aún')
    },
  ]
  return (
    <>
    <Topbar
      user='Fundación María Fortaleza'
    />
    <div className='feed'>
    <Link to='/forms' style={{textDecoration: 'none', color: '#6100DD'}}><h2>Formatos</h2></Link>
      <div className="feed__container">
          <h3>Limpieza y ambiente</h3>
          <div className="feed__cards">
            {
              data.map( item => 
                <Link to={item.path} style={{textDecoration: 'none'}} >
                  <OptionCard
                      id={item.id}
                      icon={item.icon}
                      title={item.title}
                      description={item.description}
                      onClick={item.onClick}
                    />
                </Link>
                )
            }
          </div>
      </div>
    </div>
    <Navbar/>
    </>
  )
}

export default Limpieza;