import React from 'react';
import '../App.css';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { UserAuthContextProvider } from '../context/UserAuthContext';
import Login from '../pages/Login/Login';
import Feed from '../pages/Feed/Feed';
import Forms from '../pages/forms/Forms';
import Admin from '../pages/forms/admin/Admin';
import Form18 from '../pages/forms/admin/forms/Form18';
import Limpieza from '../pages/forms/limpieza/Limpieza';
import Form15 from '../pages/forms/limpieza/forms/Form15';
import Form16 from '../pages/forms/limpieza/forms/Form16';
import Form4 from '../pages/forms/limpieza/forms/Form4';
import Higiene from '../pages/forms/limpieza/forms/Higiene';
import Eventos from '../pages/forms/eventos/Eventos';
import Residuos from '../pages/forms/residuos/Residuos';
import Form7 from '../pages/forms/residuos/forms/Form7';
import Form8 from '../pages/forms/residuos/forms/Form8';
import Documentos from '../pages/documentos/Documentos';
import Bienestar from '../pages/bienestar/Bienestar';
import Soporte from '../pages/soporte/Soporte';
import Profile from '../pages/profile/Profile';

function App() {
  return (
  <UserAuthContextProvider>
  <Router>
      <Routes>
        {/* <Route exact path='/' element={<Login/>}/> */}
        <Route exact path='/' element={<Feed/>}/>
        <Route exact path='/forms' element={<Forms/>}/>
        <Route exact path='/formatos-admin' element={<Admin/>}/>
          <Route exact path='/formatos-admin-formato18' element={<Form18/>}/>
        <Route exact path='/formatos-limpieza' element={<Limpieza/>}/>
          <Route exact path='/formatos-limpieza-formato15' element={<Form15/>}/>
          <Route exact path='/formatos-limpieza-formato16' element={<Form16/>}/>
          <Route exact path='/formatos-limpieza-formato4' element={<Form4/>}/>
          <Route exact path='/formatos-limpieza-higiene-manos' element={<Higiene/>}/>
        <Route exact path='/formatos-eventos' element={<Eventos/>}/>
        <Route exact path='/formatos-residuos' element={<Residuos/>}/>
          <Route exact path='/formatos-residuos-formato7' element={<Form7/>}/>
          <Route exact path='/formatos-residuos-formato8' element={<Form8/>}/>
        <Route exact path='/Documentos' element={<Documentos/>}/>
        <Route exact path='/bienestar' element={<Bienestar/>}/>
        <Route exact path='/soporte' element={<Soporte/>} />
        <Route exact path='/perfil' element={<Profile/>} />
      </Routes>
  </Router>
  </UserAuthContextProvider>
  );
}

export default App;
