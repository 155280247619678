import React from 'react';
import '../Form.scss';
import { Link } from 'react-router-dom';
import Topbar from '../../../components/topbar/Topbar';
import OptionCard from '../../../components/optionCard/OptionCard';
import Navbar from '../../../components/navbar/Navbar';
import { MdReportGmailerrorred, MdOutlineCleaningServices, MdOutlineMedicalServices, MdStorefront, MdDeleteOutline } from "react-icons/md";

function Eventos() {

  const data = [
    {
        id: '1',
        path: '/formatos-limpieza',
        icon: <MdStorefront/>,
        title: 'Reporte eventos adversos',
        description: 'Estrategia multimodal',
        onClick: ()=>alert('Este formulario no esta aún dispinible')
    }
  ]
  return (
    <>
    <Topbar
      user='Fundación María Fortaleza'
    />
    <div className='feed'>
    <Link to='/forms' style={{textDecoration: 'none', color: '#6100DD'}}><h2>Formatos</h2></Link>      <div className="feed__container">
          <h3>Eventos</h3>
          <div className="feed__cards">
            {
              data.map( item => 
                  <OptionCard
                      id={item.id}
                      icon={item.icon}
                      title={item.title}
                      description={item.description}
                      onClick={item.onClick}
                    />
                )
            }
          </div>
      </div>
    </div>
    <Navbar/>
    </>
  )
}

export default Eventos;