import React from 'react';
import '../../Form.scss';
import Topbar from '../../../../components/topbar/Topbar';
import Input from '../../../../components/input/Input';
import Navbar from '../../../../components/navbar/Navbar';
import Button from '../../../../components/button/Button';
import Swal from 'sweetalert2';


function Form8() {

  const handleSubmit = (e) => {
    e.preventDefault(e);
    alert('¡Los datos han sido subidos exitosamente!')
}
   
  return (
   <>
   <Topbar/>
   <div className="form">
    <h2>Lista de chequeo para rondas de seguridad gestión residuos hospitalarios</h2>
    <div className="form__container">
        <form onSubmit={handleSubmit}>
            <br />
            <h3>Actividades de manejo integral</h3>
            <br />
            <h3>Almacenamiento primario</h3>
            <br />
            <p>¿Cuenta con bolsas de color verde, negro , blanco y rojo; suficientes para el servicio?</p>
          <select name="select" >
              <option hidden defaultValue>Elige la opción</option>
              <option value="Cumple">Cumple</option>
              <option value="No cumple">No cumple</option>
              <option value="No aplica">No aplica</option>
          </select> 
            <p>¿Cuenta con contenedores de color verde, Negro y blanco ; suficientes para el servicio?</p>
          <select name="select" >
              <option hidden defaultValue>Elige la opción</option>
              <option value="Cumple">Cumple</option>
              <option value="No cumple">No cumple</option>
              <option value="No aplica">No aplica</option>
          </select> 
            <p>¿Los contenedores se encuentran en buen estado?</p>
          <select name="select" >
              <option hidden defaultValue>Elige la opción</option>
              <option value="Cumple">Cumple</option>
              <option value="No cumple">No cumple</option>
              <option value="No aplica">No aplica</option>
          </select> 
            <p>¿Los guardianes utilizados están debidamente rotulados?</p>
          <select name="select" >
              <option hidden defaultValue>Elige la opción</option>
              <option value="Cumple">Cumple</option>
              <option value="No cumple">No cumple</option>
              <option value="No aplica">No aplica</option>
          </select> 
            <p>¿Los guardianes únicamente se llenan hasta sus tres cuartas partes?</p>
          <select name="select" >
              <option hidden defaultValue>Elige la opción</option>
              <option value="Cumple">Cumple</option>
              <option value="No cumple">No cumple</option>
              <option value="No aplica">No aplica</option>
          </select> 
            <p>¿Los guardianes no tienen riesgo de caerse, voltearse o producir derrame?</p>
          <select name="select" >
              <option hidden defaultValue>Elige la opción</option>
              <option value="Cumple">Cumple</option>
              <option value="No cumple">No cumple</option>
              <option value="No aplica">No aplica</option>
          </select> 
            <p>¿La ubicación de los contenedores se ciñe a lo establecido en el PGIRASA?</p>
          <select name="select" >
              <option hidden defaultValue>Elige la opción</option>
              <option value="Cumple">Cumple</option>
              <option value="No cumple">No cumple</option>
              <option value="No aplica">No aplica</option>
          </select> 
            <p>¿El color de la bolsa corresponde al color del contenedor?</p>
          <select name="select" >
              <option hidden defaultValue>Elige la opción</option>
              <option value="Cumple">Cumple</option>
              <option value="No cumple">No cumple</option>
              <option value="No aplica">No aplica</option>
          </select> 
          <Input
            label='Observaciones' 
            type="text" 
          />  
          {/* segregacion */}
            <br />
            <h3>Segregación</h3>
            <br />
            <p>¿El personal del área ha recibido capacitación sobre el manejo de residuos hospitalarios?</p>
          <select name="select" >
              <option hidden defaultValue>Elige la opción</option>
              <option value="Cumple">Cumple</option>
              <option value="No cumple">No cumple</option>
              <option value="No aplica">No aplica</option>
          </select> 
            <p>¿El personal realiza una adecuada segregación de residuos hospitalarios y similares?</p>
          <select name="select" >
              <option hidden defaultValue>Elige la opción</option>
              <option value="Cumple">Cumple</option>
              <option value="No cumple">No cumple</option>
              <option value="No aplica">No aplica</option>
          </select> 
            <p>¿El personal utiliza el dispositivo del guardián para retirar la aguja?</p>
          <select name="select" >
              <option hidden defaultValue>Elige la opción</option>
              <option value="Cumple">Cumple</option>
              <option value="No cumple">No cumple</option>
              <option value="No aplica">No aplica</option>
          </select> 
            <p>¿Las agujas se disponen en el guardián con el capuchón o protector puesto?</p>
          <select name="select" >
              <option hidden defaultValue>Elige la opción</option>
              <option value="Cumple">Cumple</option>
              <option value="No cumple">No cumple</option>
              <option value="No aplica">No aplica</option>
          </select> 
            <p>¿Se observa que el servicio esta realizando reciclaje?</p>
          <select name="select" >
              <option hidden defaultValue>Elige la opción</option>
              <option value="Cumple">Cumple</option>
              <option value="No cumple">No cumple</option>
              <option value="No aplica">No aplica</option>
          </select> 
            <p>¿Los contenedores están debidamente tapados? - Según sea su funcionalidad.</p>
          <select name="select" >
              <option hidden defaultValue>Elige la opción</option>
              <option value="Cumple">Cumple</option>
              <option value="No cumple">No cumple</option>
              <option value="No aplica">No aplica</option>
          </select> 
            <p>¿Se reutilizan residuos como insumos en alguna de las actividades de la prestación del servicio? </p>
          <select name="select" >
              <option hidden defaultValue>Elige la opción</option>
              <option value="Cumple">Cumple</option>
              <option value="No cumple">No cumple</option>
              <option value="No aplica">No aplica</option>
          </select> 
          <Input
            label='Observaciones' 
            type="text" 
          />  
          {/* DESACTIVACIÓN - ALMACENAMIENTO INTERMEDIO - TRANSPORTE INTERNO*/}
            <br />
            <h3>Desactivación - Almacenamiento intermedio - Tanspore interno</h3>
            <br />
            <p>¿Las bolsas con residuos nunca son arrastradas por el suelo, ni se comprimen unas con otras.</p>
          <select name="select" >
              <option hidden defaultValue>Elige la opción</option>
              <option value="Cumple">Cumple</option>
              <option value="No cumple">No cumple</option>
              <option value="No aplica">No aplica</option>
          </select> 
            <p>¿La puerta del almacenamiento intermedio permanece siempre cerrada?</p>
          <select name="select" >
              <option hidden defaultValue>Elige la opción</option>
              <option value="Cumple">Cumple</option>
              <option value="No cumple">No cumple</option>
              <option value="No aplica">No aplica</option>
          </select> 
            <p>¿El espacio destinado para el almacenamiento intermedio esta debidamente señalizado?</p>
          <select name="select" >
              <option hidden defaultValue>Elige la opción</option>
              <option value="Cumple">Cumple</option>
              <option value="No cumple">No cumple</option>
              <option value="No aplica">No aplica</option>
          </select> 
            <p>¿El área del almacenamiento esta limpia, ordenada y desinfectada? Para evitar microorganismos y vectores.</p>
          <select name="select" >
              <option hidden defaultValue>Elige la opción</option>
              <option value="Cumple">Cumple</option>
              <option value="No cumple">No cumple</option>
              <option value="No aplica">No aplica</option>
          </select> 
            <p>¿El periodo de almacenamiento de residuos no supera las 12 horas.</p>
          <select name="select" >
              <option hidden defaultValue>Elige la opción</option>
              <option value="Cumple">Cumple</option>
              <option value="No cumple">No cumple</option>
              <option value="No aplica">No aplica</option>
          </select> 
            <p>¿La recolección de los residuos se realiza de acuerdo a la frecuencia de generación del servicio o hasta que el recipiente este lleno hasta sus tres cuartas partes.</p>
          <select name="select" >
              <option hidden defaultValue>Elige la opción</option>
              <option value="Cumple">Cumple</option>
              <option value="No cumple">No cumple</option>
              <option value="No aplica">No aplica</option>
          </select> 
            <p>¿El transporte de los residuos se realiza en carros, de acuerdo a las rutas y horarios establecidos en el Plan.</p>
          <select name="select" >
              <option hidden defaultValue>Elige la opción</option>
              <option value="Cumple">Cumple</option>
              <option value="No cumple">No cumple</option>
              <option value="No aplica">No aplica</option>
          </select> 
            <p>Se transporta residuos manualmente cuando las bolsas son pequeñas y el carro no tiene fácil acceso.</p>
          <select name="select" >
              <option hidden defaultValue>Elige la opción</option>
              <option value="Cumple">Cumple</option>
              <option value="No cumple">No cumple</option>
              <option value="No aplica">No aplica</option>
          </select> 
            <p>Los residuos de alimentos se almacenan, se transportan y se manejan por separado.</p>
          <select name="select" >
              <option hidden defaultValue>Elige la opción</option>
              <option value="Cumple">Cumple</option>
              <option value="No cumple">No cumple</option>
              <option value="No aplica">No aplica</option>
          </select> 
            <p>La montacargas de uso exclusivo para residuos peligrosos, se lava y desinfecta.</p>
          <select name="select" >
              <option hidden defaultValue>Elige la opción</option>
              <option value="Cumple">Cumple</option>
              <option value="No cumple">No cumple</option>
              <option value="No aplica">No aplica</option>
          </select> 
          <Input
            label='Observaciones' 
            type="text" 
          />  
 
          {/* ALMACENAMIENTO CENTRAL - TRATAMIENTO - GESTIÓN EXTERNA*/}
            <br />
            <h3>Almacenamiento central - Tratamiento - Gestión externa</h3>
            <br />
            <p>Se limpia y se desinfecta el almacenamiento después de la evacuación de los residuos?</p>
          <select name="select" >
              <option hidden defaultValue>Elige la opción</option>
              <option value="Cumple">Cumple</option>
              <option value="No cumple">No cumple</option>
              <option value="No aplica">No aplica</option>
          </select> 
            <p>¿Las bolsas con residuos se apilan sin compactarse?</p>
          <select name="select" >
              <option hidden defaultValue>Elige la opción</option>
              <option value="Cumple">Cumple</option>
              <option value="No cumple">No cumple</option>
              <option value="No aplica">No aplica</option>
          </select> 
            <p>¿Se lleva el control del peso generado por cada tipo de residuos?</p>
          <select name="select" >
              <option hidden defaultValue>Elige la opción</option>
              <option value="Cumple">Cumple</option>
              <option value="No cumple">No cumple</option>
              <option value="No aplica">No aplica</option>
          </select> 
            <p>Las áreas de almacenamiento están debidamente señalizadas?.</p>
          <select name="select" >
              <option hidden defaultValue>Elige la opción</option>
              <option value="Cumple">Cumple</option>
              <option value="No cumple">No cumple</option>
              <option value="No aplica">No aplica</option>
          </select> 
            <p>Las bolsas con los residuos llegan en sus respectivos carros transportadores?</p>
          <select name="select" >
              <option hidden defaultValue>Elige la opción</option>
              <option value="Cumple">Cumple</option>
              <option value="No cumple">No cumple</option>
              <option value="No aplica">No aplica</option>
          </select> 
            <p>El personal que manipula los residuos cuenta con los elementos de protección personal?</p>
          <select name="select" >
              <option hidden defaultValue>Elige la opción</option>
              <option value="Cumple">Cumple</option>
              <option value="No cumple">No cumple</option>
              <option value="No aplica">No aplica</option>
          </select> 
            <p>¿Se exige al proveedor del tratamiento los recibos de recolección en cada una de las entregas?</p>
          <select name="select" >
              <option hidden defaultValue>Elige la opción</option>
              <option value="Cumple">Cumple</option>
              <option value="No cumple">No cumple</option>
              <option value="No aplica">No aplica</option>
          </select> 
            <p>¿Se diligencia por parte del proveedor el registro de trazabilidad de residuos?, y este es entregado oportunamente?</p>
          <select name="select" >
              <option hidden defaultValue>Elige la opción</option>
              <option value="Cumple">Cumple</option>
              <option value="No cumple">No cumple</option>
              <option value="No aplica">No aplica</option>
          </select> 
            <p>¿Se cuenta con certificaciones mensuales del tratamiento realizado a los residuos peligrosos?</p>
          <select name="select" >
              <option hidden defaultValue>Elige la opción</option>
              <option value="Cumple">Cumple</option>
              <option value="No cumple">No cumple</option>
              <option value="No aplica">No aplica</option>
          </select> 
            <p>¿Se realizan actas para el material reciclable que sale de la institución?</p>
          <select name="select" >
              <option hidden defaultValue>Elige la opción</option>
              <option value="Cumple">Cumple</option>
              <option value="No cumple">No cumple</option>
              <option value="No aplica">No aplica</option>
          </select> 
          <Input
            label='Observaciones' 
            type="text" 
          />  
          {/* CUARTOS Y CARROS DE ASEO*/}
            <br />
            <h3>Cuartos y carros de aseo</h3>
            <br />
            <p>¿Los elementos usados para la limpieza y desinfección están debidamente ubicados?</p>
          <select name="select" >
              <option hidden defaultValue>Elige la opción</option>
              <option value="Cumple">Cumple</option>
              <option value="No cumple">No cumple</option>
              <option value="No aplica">No aplica</option>
          </select> 
            <p>¿Los elementos usados para la limpieza y desinfección están debidamente rotulados?</p>
          <select name="select" >
              <option hidden defaultValue>Elige la opción</option>
              <option value="Cumple">Cumple</option>
              <option value="No cumple">No cumple</option>
              <option value="No aplica">No aplica</option>
          </select> 
            <p>¿Los elementos usados para la limpieza y desinfección se encuentran limpios?</p>
          <select name="select" >
              <option hidden defaultValue>Elige la opción</option>
              <option value="Cumple">Cumple</option>
              <option value="No cumple">No cumple</option>
              <option value="No aplica">No aplica</option>
          </select> 
            <p>¿Los líquidos usados para la limpieza y desinfección están completos?</p>
          <select name="select" >
              <option hidden defaultValue>Elige la opción</option>
              <option value="Cumple">Cumple</option>
              <option value="No cumple">No cumple</option>
              <option value="No aplica">No aplica</option>
          </select> 
            <p>¿Los líquidos usados para la limpieza y desinfección están debidamente rotulados?</p>
          <select name="select" >
              <option hidden defaultValue>Elige la opción</option>
              <option value="Cumple">Cumple</option>
              <option value="No cumple">No cumple</option>
              <option value="No aplica">No aplica</option>
          </select> 
            <p>¿Los líquidos usados para la limpieza y desinfección están marcados con la fecha de inicio de consumo?</p>
          <select name="select" >
              <option hidden defaultValue>Elige la opción</option>
              <option value="Cumple">Cumple</option>
              <option value="No cumple">No cumple</option>
              <option value="No aplica">No aplica</option>
          </select> 
            <p>¿Los atomizadores usados para la limpieza y desinfección están debidamente rotulados?</p>
          <select name="select" >
              <option hidden defaultValue>Elige la opción</option>
              <option value="Cumple">Cumple</option>
              <option value="No cumple">No cumple</option>
              <option value="No aplica">No aplica</option>
          </select> 
            <p>¿Los atomizadores usados para la limpieza y desinfección se encuentran en buen estado?</p>
          <select name="select" >
              <option hidden defaultValue>Elige la opción</option>
              <option value="Cumple">Cumple</option>
              <option value="No cumple">No cumple</option>
              <option value="No aplica">No aplica</option>
          </select> 
          <Input
            label='Observaciones' 
            type="text" 
          />  
          {/* PERSONAL DE LIMPIEZA Y DESINFECCIÓN*/}
            <br />
            <h3>Personal de limpieza y desinfección</h3>
            <br />
            <p>¿El personal de limpieza y desinfección se encuentra en su área de trabajo al momento de la visita?</p>
            <select name="select" >
              <option hidden defaultValue>Elige la opción</option>
              <option value="Cumple">Cumple</option>
              <option value="No cumple">No cumple</option>
              <option value="No aplica">No aplica</option>
          </select> 
            <p>El personal de limpieza y desinfección porta la escarapela?</p>
          <select name="select" >
              <option hidden defaultValue>Elige la opción</option>
              <option value="Cumple">Cumple</option>
              <option value="No cumple">No cumple</option>
              <option value="No aplica">No aplica</option>
          </select> 
            <p>¿El personal de limpieza y desinfección cuenta con la dotación adecuada?</p>
          <select name="select" >
              <option hidden defaultValue>Elige la opción</option>
              <option value="Cumple">Cumple</option>
              <option value="No cumple">No cumple</option>
              <option value="No aplica">No aplica</option>
          </select> 
            <p>¿El personal de limpieza y desinfección cuenta con los elementos de protección personal necesarios?</p>
          <select name="select" >
              <option hidden defaultValue>Elige la opción</option>
              <option value="Cumple">Cumple</option>
              <option value="No cumple">No cumple</option>
              <option value="No aplica">No aplica</option>
          </select> 
            <p>¿Los elementos de protección personal tienen asignado un lugar para conservarlos en condiciones optimas?</p>
          <select name="select" >
              <option hidden defaultValue>Elige la opción</option>
              <option value="Cumple">Cumple</option>
              <option value="No cumple">No cumple</option>
              <option value="No aplica">No aplica</option>
          </select>   
          <Input
            label='Observaciones' 
            type="text" 
          />        
          {/* ÁREAS*/}
            <br />
            <h3>Áreas</h3>
            <br />
            <p>¿El piso del área se encuentra limpio al momento de la visita?</p>
            <select name="select" >
              <option hidden defaultValue>Elige la opción</option>
              <option value="Cumple">Cumple</option>
              <option value="No cumple">No cumple</option>
              <option value="No aplica">No aplica</option>
          </select> 
            <p>¿Las ventanas se encuentran limpias al momento de la visita?</p>
          <select name="select" >
              <option hidden defaultValue>Elige la opción</option>
              <option value="Cumple">Cumple</option>
              <option value="No cumple">No cumple</option>
              <option value="No aplica">No aplica</option>
          </select> 
            <p>¿Las carteleras y mobiliario se encuentran desempolvados al momento de la visita?</p>
          <select name="select" >
              <option hidden defaultValue>Elige la opción</option>
              <option value="Cumple">Cumple</option>
              <option value="No cumple">No cumple</option>
              <option value="No aplica">No aplica</option>
          </select> 
            <p>En general, ¿el área visitada se encuentra en buenas condiciones de limpieza?</p>
          <select name="select" >
              <option hidden defaultValue>Elige la opción</option>
              <option value="Cumple">Cumple</option>
              <option value="No cumple">No cumple</option>
              <option value="No aplica">No aplica</option>
          </select>  
          <Input
            label='Observaciones' 
            type="text" 
          />        
          {/* ACTIVIDADES DE 5 Ss*/}
            <br />
            <h3>Actividades de 5 Ss</h3>
            <br />
            <p>¿Los equipos, maquinas y herramientas se encuentran limpios y libres de materiales innecesarios?</p>
            <select name="select" >
              <option hidden defaultValue>Elige la opción</option>
              <option value="Cumple">Cumple</option>
              <option value="No cumple">No cumple</option>
              <option value="No aplica">No aplica</option>
          </select> 
            <p>¿Los pasillos tienen superficies limpias, seguras y se encuentran libres de obstrucciones y/o desperdicios?</p>
          <select name="select" >
              <option hidden defaultValue>Elige la opción</option>
              <option value="Cumple">Cumple</option>
              <option value="No cumple">No cumple</option>
              <option value="No aplica">No aplica</option>
          </select> 
            <p>¿Las paredes, techos, pisos están en buen estado?</p>
          <select name="select" >
              <option hidden defaultValue>Elige la opción</option>
              <option value="Cumple">Cumple</option>
              <option value="No cumple">No cumple</option>
              <option value="No aplica">No aplica</option>
          </select> 
            <p>¿Los insumos están guardados en un lugar específico para ello?</p>
          <select name="select" >
              <option hidden defaultValue>Elige la opción</option>
              <option value="Cumple">Cumple</option>
              <option value="No cumple">No cumple</option>
              <option value="No aplica">No aplica</option>
          </select>        
            <p>¿Hay contaminación visual por exceso de publicidad?</p>
          <select name="select" >
              <option hidden defaultValue>Elige la opción</option>
              <option value="Cumple">Cumple</option>
              <option value="No cumple">No cumple</option>
              <option value="No aplica">No aplica</option>
          </select>        
            <p>¿Se cuenta con un número suficiente de baños?</p>
          <select name="select" >
              <option hidden defaultValue>Elige la opción</option>
              <option value="Cumple">Cumple</option>
              <option value="No cumple">No cumple</option>
              <option value="No aplica">No aplica</option>
          </select>        
            <p>¿Los baños están debidamente dotados?</p>
          <select name="select" >
              <option hidden defaultValue>Elige la opción</option>
              <option value="Cumple">Cumple</option>
              <option value="No cumple">No cumple</option>
              <option value="No aplica">No aplica</option>
          </select>        
            <p>¿Los lockers y vestier del personal están en buen estado?</p>
          <select name="select" >
              <option hidden defaultValue>Elige la opción</option>
              <option value="Cumple">Cumple</option>
              <option value="No cumple">No cumple</option>
              <option value="No aplica">No aplica</option>
          </select>        
            <p>¿Se tienen definido (por escrito) y se han divulgado, estándares de aseo a los trabajadores?</p>
          <select name="select" >
              <option hidden defaultValue>Elige la opción</option>
              <option value="Cumple">Cumple</option>
              <option value="No cumple">No cumple</option>
              <option value="No aplica">No aplica</option>
          </select>        
            <p>¿Se cuenta con un mantenimiento periódico de baños?</p>
          <select name="select" >
              <option hidden defaultValue>Elige la opción</option>
              <option value="Cumple">Cumple</option>
              <option value="No cumple">No cumple</option>
              <option value="No aplica">No aplica</option>
          </select>        
            <p>¿Existen servicios sanitarios separados por genero?</p>
          <select name="select" >
              <option hidden defaultValue>Elige la opción</option>
              <option value="Cumple">Cumple</option>
              <option value="No cumple">No cumple</option>
              <option value="No aplica">No aplica</option>
          </select>        
            <p>¿El personal cuenta con servicio de ducha para después de la jornada de trabajo?</p>
          <select name="select" >
              <option hidden defaultValue>Elige la opción</option>
              <option value="Cumple">Cumple</option>
              <option value="No cumple">No cumple</option>
              <option value="No aplica">No aplica</option>
          </select>        
            <p>¿Los vestier, lockers, baños y duchas, se encuentran limpios y libres de regueros y/o residuos?</p>
          <select name="select" >
              <option hidden defaultValue>Elige la opción</option>
              <option value="Cumple">Cumple</option>
              <option value="No cumple">No cumple</option>
              <option value="No aplica">No aplica</option>
          </select>        
            <p>¿Se realiza control periódico de plagas y roedores?</p>
          <select name="select" >
              <option hidden defaultValue>Elige la opción</option>
              <option value="Cumple">Cumple</option>
              <option value="No cumple">No cumple</option>
              <option value="No aplica">No aplica</option>
          </select>        
            <p>¿Se realiza control periódico de plagas y roedores?</p>
          <select name="select" >
              <option hidden defaultValue>Elige la opción</option>
              <option value="Cumple">Cumple</option>
              <option value="No cumple">No cumple</option>
              <option value="No aplica">No aplica</option>
          </select>        
            <p>¿A las luminarias se les hace una limpieza periódica?</p>
          <select name="select" >
              <option hidden defaultValue>Elige la opción</option>
              <option value="Cumple">Cumple</option>
              <option value="No cumple">No cumple</option>
              <option value="No aplica">No aplica</option>
          </select> 
          <Input
            label='Observaciones' 
            type="text" 
          />       
        <div className="form__btn">
            <Button
                title='Enviar'
            />
        </div>
        </form>
    </div>
.

   </div>
   <Navbar/>
   </>
  )
}

export default Form8;