import React from 'react';
import './OptionCard.scss';

function Card( { icon, title, description, onClick }) {
  return (
    <>
    <div className="optionCard" onClick={onClick}>
      <div className="optionCard__container">
        <div className="optionCard__info">
          <h3>{title}</h3>
        </div>
      </div>
    </div>
    </>
  )
}

export default Card