import React from 'react';
import './Form.scss';
import { Link } from 'react-router-dom';
import Topbar from '../../components/topbar/Topbar';
import Card from '../../components/card/Card';
import Navbar from '../../components/navbar/Navbar';
import { MdReportGmailerrorred, MdOutlineCleaningServices, MdOutlineMedicalServices, MdStorefront, MdDeleteOutline } from "react-icons/md";

function Forms() {

  const data = [
    {
        id: '1',
        path: '/formatos-admin',
        icon: <MdStorefront/>,
        title: 'Administración',
        description: 'Estrategia multimodal',
    },
    {
        id: '2',
        path: '/formatos-limpieza',
        icon: <MdOutlineCleaningServices/>,
        title: 'Limpieza y ambiente',
        description: 'IAAS',
    },
    // {
    //     id: '3',
    //     path: '/feed',
    //     icon: <MdOutlineMedicalServices/>,
    //     title: 'Medicamentos',
    //     description: 'IAAS',
    // },
     
    {
        id: '4',
        path: '/formatos-eventos',
        icon: <MdReportGmailerrorred/>,
        title: 'Eventos',
        description: 'IAAS',
    },
    {
        id: '5',
        path: '/formatos-residuos',
        icon: <MdDeleteOutline/>,
        title: 'Residuos',
        description: 'Estrategia multimodal',
    },

 
  ]
  return (
    <>
    <Topbar
      user='Fundación María Fortaleza'
    />
    <div className='feed'>
        <h2>Formatos</h2>
      <div className="feed__container">
          <h3>Categorías</h3>
          <div className="feed__cards">
            {
              data.map( item => 
                <Link to={item.path} style={{textDecoration: 'none'}} >
                  <Card
                      id={item.id}
                      icon={item.icon}
                      title={item.title}
                      description={item.description}
                    />
                </Link>
                 
                )
            }
          </div>
      </div>
    </div>
    <Navbar/>
    </>
  )
}

export default Forms;