import React, { useState } from 'react';
import '../../Form.scss';
import { useNavigate } from 'react-router-dom';
import Topbar from '../../../../components/topbar/Topbar';
import Input from '../../../../components/input/Input';
import Navbar from '../../../../components/navbar/Navbar';
import Button from '../../../../components/button/Button';
import Swal from 'sweetalert2';
import { db } from '../../../../firebase/firebaseConfig';
import { collection, addDoc } from 'firebase/firestore';
import { useUserAuth } from '../../../../context/UserAuthContext';


function Form7() {
    const data = [
        {
            key: '1',
            label: 'No aprovechables(Kg)',
            type:'number',
            placeholder: 'Kg',
            name: '',
            onChange: (e)=>setNoPeligrosos1,
        },
        {
            key: '2',
            label: 'Aprovechables - reciclabes(Kg)',
            type:'number',
            placeholder: 'Kg',
            name: '',
            onChange: (e)=>setNoPeligrosos2,
        },
        {
            key: '3',
            label: 'Aprovechables - orgánicos(Kg)',
            type:'number',
            placeholder: 'Kg',
            name: '',
            onChange: (e)=>setNoPeligrosos3,
        },
    ];

    const [noPeligrosos1, setNoPeligrosos1] = useState('');
    const [noPeligrosos2, setNoPeligrosos2] = useState('');
    const [noPeligrosos3, setNoPeligrosos3] = useState('');
    const [peligrosos1, setPeligrosos1] = useState('');
    const [peligrosos2, setPeligrosos2] = useState('');
    const [peligrosos3, setPeligrosos3] = useState('');
    const [quimicos1, setQuimicos1] = useState('');
    const [quimicos2, setQuimicos2] = useState('');
    const [otros, setOtros] = useState('');

    
    const date = new Date();
    const navigate = useNavigate();
    const formCollection = collection(db, 'Formato Formulario RH1 fuentes de generación y clases de residuos');

    const handleSubmit = async (e) => {
        e.preventDefault(e);
        await addDoc(formCollection, { 
            date: date,
            noPeligrosos1: noPeligrosos1,
            noPeligrosos2: noPeligrosos2,
            noPeligrosos3: noPeligrosos3,
            peligrosos1: peligrosos1,
            peligrosos2: peligrosos2,
            peligrosos3: peligrosos3,
            quimicos1: quimicos1,
            quimicos2: quimicos2,
            otros: otros
        })
        .then(()=> {
            Swal.fire(
                '¡Buen trabajo!',
                'El forumlario ha sido subido exitosamente',
                'success'
            );
        })
        .then(() => navigate('/formatos-limpieza'))
    }
  return (
   <>
   <Topbar/>
   <div className="form">
    <h2>Formato Formulario RH1 fuentes de generación y clases de residuos</h2>
    <div className="form__container">
        <form onSubmit={handleSubmit}>
            <br />
            <h3>Residuos no peligrosos</h3>
            {
                data.map(item => 
                    <Input
                        label={item.label}
                        type={item.type}
                        placeholder={item.placeholder}
                        value={item.value}
                        name={item.name}
                        onChange={item.onChange}
                    />
                )
            }
            <br />
            <h3>Residuos peligrosos</h3>
            <br />
            <h4>Riesgo Biológico</h4>
                <Input
                    label='Biosanitarios(Kg)'
                    type='number'
                    placeholder='Kg'
                    name=''
                    onChange={(e)=>setPeligrosos1}
                />
                <Input
                    label='Cortopunzantes(Kg)'
                    type='number'
                    placeholder='Kg'
                    name=''
                    onChange={(e)=>setPeligrosos2}
                />
                <Input
                    label='Anatomopatológico(Kg)'
                    type='number'
                    placeholder='Kg'
                    name=''
                    onChange={(e)=>setPeligrosos3}
                />
                <br />
            <h4>Químicos</h4>
                <Input
                    label='Fármacos(Kg)'
                    type='number'
                    placeholder='Kg'
                    name=''
                    onChange={(e)=>setQuimicos1}
                />
                <Input
                    label='Citotóxicos(Kg)'
                    type='number'
                    placeholder='Kg'
                    name=''
                    onChange={(e)=>setQuimicos2}
                />           
                <br />
            <h4>Otros</h4>
                <Input
                    label='Otros (Kg)'
                    type='number'
                    placeholder='Kg'
                    name=''
                    onChange={(e)=>setOtros}
                />  
                <div className="form__btn">
                    <Button
                        title='Enviar'
                    />
                </div>

        </form>
    </div>
.

   </div>
   <Navbar/>
   </>
  )
}

export default Form7