import { createContext, useContext, useState, useEffect } from 'react';
import { auth } from '../firebase/firebaseConfig';
import { signInWithEmailAndPassword, signOut,onAuthStateChanged,} from 'firebase/auth';

const userAuthContext = createContext();

export function UserAuthContextProvider({ children }) {
    const [user, setUser] = useState('');

    function logIn(email, password) {
        return signInWithEmailAndPassword(auth, email, password)
    }

    function logOut() {
        return signOut(auth);
    }

    useEffect(() => {
        const unsubscribe = onAuthStateChanged(auth, (currentUser) => {
            setUser(currentUser);
        });
        return () => {
            unsubscribe();
        }
    }, []);
    return (
    <userAuthContext.Provider value={{user, logIn, logOut}}>
        {children}
    </userAuthContext.Provider>   
    );
}

export function useUserAuth() {
    return useContext(userAuthContext);
}