import React, { useState } from 'react';
import '../../Form.scss';
import { useNavigate } from 'react-router-dom';
import Topbar from '../../../../components/topbar/Topbar';
import Input from '../../../../components/input/Input';
import Navbar from '../../../../components/navbar/Navbar';
import Button from '../../../../components/button/Button';
import Swal from 'sweetalert2';
import { db } from '../../../../firebase/firebaseConfig';
import { collection, addDoc } from 'firebase/firestore'
import { useUserAuth } from '../../../../context/UserAuthContext';



function Higiene() {

  const [servicio, setServicio] = useState('');
  const [limpieza1, setLimpieza1] = useState('');
  const [limpieza2, setLimpieza2] = useState('');
  const [limpieza3, setLimpieza3] = useState('');
  const [limpieza4, setLimpieza4] = useState('');
  const [limpieza5, setLimpieza5] = useState('');
  const [limpieza6, setLimpieza6] = useState('');
  const [limpieza7, setLimpieza7] = useState('');
  const [limpieza8, setLimpieza8] = useState('');
  const [limpieza9, setLimpieza9] = useState('');
  const [limpieza10, setLimpieza10] = useState('');
  const [limpieza11, setLimpieza11] = useState('');
  const [limpieza12, setLimpieza12] = useState('');
  const [limpieza13, setLimpieza13] = useState('');
  const [limpieza14, setLimpieza14] = useState('');
  const [limpieza15, setLimpieza15] = useState('');
  const [limpieza16, setLimpieza16] = useState('');
  const [limpieza17, setLimpieza17] = useState('');
  const [limpieza18, setLimpieza18] = useState('');
  const [limpieza19, setLimpieza19] = useState('');
  const [limpieza20, setLimpieza20] = useState('');
  const [limpieza21, setLimpieza21] = useState('');
  const [limpieza22, setLimpieza22] = useState('');
  const [limpieza23, setLimpieza23] = useState('');
  const [observacion, setObservacion] = useState('');

  const navigate = useNavigate();
  const date = new Date();
  const { user } = useUserAuth();
  const formCollection = collection(db, 'Registro de lavado de manos')


    const handleSubmit = async (e) => {
        e.preventDefault(e);
        await addDoc(formCollection, {
          date: date,
          servicio: servicio,
          limpieza1: limpieza1,
          limpieza2: limpieza2,
          limpieza3: limpieza3,
          limpieza4: limpieza4,
          limpieza5: limpieza5,
          limpieza6: limpieza6,
          limpieza7: limpieza1,
          limpieza8: limpieza2,
          limpieza9: limpieza3,
          limpieza10: limpieza10,
          limpieza11: limpieza11,
          limpieza12: limpieza12,
          limpieza13: limpieza13,
          limpieza14: limpieza14,
          limpieza15: limpieza15,
          limpieza16: limpieza16,
          limpieza17: limpieza17,
          limpieza19: limpieza19,
          limpieza20: limpieza20,
          limpieza21: limpieza21,
          limpieza22: limpieza22,
          limpieza23: limpieza23,
          observacion: observacion
        })
        .then(()=> {
          Swal.fire(
            '¡Buen trabajo!',
            'El forumlario ha sido subido exitosamente',
            'success'
          )
        })
        .then(()=>navigate('/formatos-limpieza'))
    }
   
  return (
   <>
   <Topbar/>
   <div className="form">
    <h2>Lavado de manos</h2>
    <div className="form__container">
        <form onSubmit={handleSubmit}>
            <br />
            <Input
                label='Servicio'
                type='text'
                onChange={(e)=>setServicio(e.target.value)}
            />
            <br />
            <p>Está disponible en el servicio el protocolo
            del lavado de manos.</p>
          <select name="select" onChange={(e)=>setLimpieza1(e.target.value)}>
              <option hidden defaultValue>Elige la opción</option>
              <option value="Cumple">Cumple</option>
              <option value="No cumple">No cumple</option>
              <option value="No aplica">No aplica</option>
          </select> 
            <p>Conoce el personal el protocolo del lavado
            de manos.</p>
          <select name="select" onChange={(e)=>setLimpieza2(e.target.value)} >
              <option hidden defaultValue>Elige la opción</option>
              <option value="Cumple">Cumple</option>
              <option value="No cumple">No cumple</option>
              <option value="No aplica">No aplica</option>
          </select> 
            <p>Los lavamanos que existen en los puntos
            de atención cuentan con suministro de
            agua permanente.</p>
          <select name="select" onChange={(e)=>setLimpieza3(e.target.value)}>
              <option hidden defaultValue>Elige la opción</option>
              <option value="Cumple">Cumple</option>
              <option value="No cumple">No cumple</option>
              <option value="No aplica">No aplica</option>
          </select> 
            <br />
            <p>Los lavamanos cuentan con dispensadores de jabón con producto disponible:
            Nombre del producto LOTE
            Fecha de vencimiento</p>
          <select name="select" onChange={(e)=>setLimpieza4(e.target.value)}>
              <option hidden defaultValue>Elige la opción</option>
              <option value="Cumple">Cumple</option>
              <option value="No cumple">No cumple</option>
              <option value="No aplica">No aplica</option>
          </select> 
            <p>El personal tiene acceso permanente a
            alcohol glicerinado.</p>
          <select name="select" onChange={(e)=>setLimpieza5(e.target.value)} >
              <option hidden defaultValue>Elige la opción</option>
              <option value="Cumple">Cumple</option>
              <option value="No cumple">No cumple</option>
              <option value="No aplica">No aplica</option>
          </select> 
            <p>Existe junto al lavamanos caneca de
            residuos ordinarios.</p>
          <select name="select" onChange={(e)=>setLimpieza3(e.target.value)}>
              <option hidden defaultValue>Elige la opción</option>
              <option value="Cumple">Cumple</option>
              <option value="No cumple">No cumple</option>
              <option value="No aplica">No aplica</option>
          </select> 
            <p>Hay suministro de toallas desechables
            constantemente en los lavamanos</p>
          <select name="select" onChange={(e)=>setLimpieza6(e.target.value)}>
              <option hidden defaultValue>Elige la opción</option>
              <option value="Cumple">Cumple</option>
              <option value="No cumple">No cumple</option>
              <option value="No aplica">No aplica</option>
          </select> 
            <p>El trabajador de salud realiza higiene de
            manos: Antes del contacto con cada paciente</p>
          <select name="select" onChange={(e)=>setLimpieza7(e.target.value)}>
              <option hidden defaultValue>Elige la opción</option>
              <option value="Cumple">Cumple</option>
              <option value="No cumple">No cumple</option>
              <option value="No aplica">No aplica</option>
          </select> 
            <p>Antes de realizar un procedimiento limpio y aséptico</p>
          <select name="select" onChange={(e)=>setLimpieza8(e.target.value)}>
              <option hidden defaultValue>Elige la opción</option>
              <option value="Cumple">Cumple</option>
              <option value="No cumple">No cumple</option>
              <option value="No aplica">No aplica</option>
          </select> 
          <br />
          <p>Después del riesgo de contacto con fluidos
orgánicos.</p>
          <select name="select" onChange={(e)=>setLimpieza9(e.target.value)}>
              <option hidden defaultValue>Elige la opción</option>
              <option value="Cumple">Cumple</option>
              <option value="No cumple">No cumple</option>
              <option value="No aplica">No aplica</option>
          </select> 
          <p>Después de haber estado en contacto con el paciente</p>
          <select name="select" onChange={(e)=>setLimpieza10(e.target.value)}>
              <option hidden defaultValue>Elige la opción</option>
              <option value="Cumple">Cumple</option>
              <option value="No cumple">No cumple</option>
              <option value="No aplica">No aplica</option>
          </select> 
          <p>Después de haber estado en contacto con el entorno del paciente</p>
          <select name="select" onChange={(e)=>setLimpieza11(e.target.value)}>
              <option hidden defaultValue>Elige la opción</option>
              <option value="Cumple">Cumple</option>
              <option value="No cumple">No cumple</option>
              <option value="No aplica">No aplica</option>
          </select> 
          <p>La técnica de lavado de manos realizado por el trabajador de salud incluye: El trabajador tiene las uñas sin esmalte.</p>
          <select name="select" onChange={(e)=>setLimpieza12(e.target.value)}>
              <option hidden defaultValue>Elige la opción</option>
              <option value="Cumple">Cumple</option>
              <option value="No cumple">No cumple</option>
              <option value="No aplica">No aplica</option>
          </select> 
          <p>Retira de los brazos y manos reloj, pulseras y anillos.</p>
          <select name="select" onChange={(e)=>setLimpieza13(e.target.value)}>
              <option hidden defaultValue>Elige la opción</option>
              <option value="Cumple">Cumple</option>
              <option value="No cumple">No cumple</option>
              <option value="No aplica">No aplica</option>
          </select> 
          <p>La técnica del lavado de manos con agua y jabón está compuesta por: Abre la llave del agua y humedece sus manos</p>
          <select name="select" onChange={(e)=>setLimpieza14(e.target.value)}>
              <option hidden defaultValue>Elige la opción</option>
              <option value="Cumple">Cumple</option>
              <option value="No cumple">No cumple</option>
              <option value="No aplica">No aplica</option>
          </select> 
          <p>Deposita en la palma de la mano una cantidad de jabón suficiente para cubrir toda la superficie de las
manos</p>
          <select name="select" onChange={(e)=>setLimpieza15(e.target.value)}>
              <option hidden defaultValue>Elige la opción</option>
              <option value="Cumple">Cumple</option>
              <option value="No cumple">No cumple</option>
              <option value="No aplica">No aplica</option>
          </select> 
          <p>Frota las palmas de las manos entre si</p>
          <select name="select" onChange={(e)=>setLimpieza16(e.target.value)}>
              <option hidden defaultValue>Elige la opción</option>
              <option value="Cumple">Cumple</option>
              <option value="No cumple">No cumple</option>
              <option value="No aplica">No aplica</option>
          </select> 
          <p>Frota la palma de la mano derecha contra el dorso de la mano izquierda entrelazando los dedos y viceversa</p>
          <select name="select" onChange={(e)=>setLimpieza17(e.target.value)}>
              <option hidden defaultValue>Elige la opción</option>
              <option value="Cumple">Cumple</option>
              <option value="No cumple">No cumple</option>
              <option value="No aplica">No aplica</option>
          </select> 
          <p>Frota las palmas de las manos entre sí, con dedos entrelazados.</p>
          <select name="select" onChange={(e)=>setLimpieza18(e.target.value)}>
              <option hidden defaultValue>Elige la opción</option>
              <option value="Cumple">Cumple</option>
              <option value="No cumple">No cumple</option>
              <option value="No aplica">No aplica</option>
          </select> 
          <p>Frota con un movimiento de rotación el pulgar izquierdo atrapándolo con la palma de la mano derecha y viceversa</p>
          <select name="select" onChange={(e)=>setLimpieza19(e.target.value)}>
              <option hidden defaultValue>Elige la opción</option>
              <option value="Cumple">Cumple</option>
              <option value="No cumple">No cumple</option>
              <option value="No aplica">No aplica</option>
          </select> 
          <p>Frota la punta de los dedos de la mano derecha contra la palma de la mano izquierda, haciendo un movimiento de rotación, y viceversa.</p>
          <select name="select" onChange={(e)=>setLimpieza20(e.target.value)}>
              <option hidden defaultValue>Elige la opción</option>
              <option value="Cumple">Cumple</option>
              <option value="No cumple">No cumple</option>
              <option value="No aplica">No aplica</option>
          </select> 
          <p>Se enjuaga las manos con agua</p>
          <select name="select" onChange={(e)=>setLimpieza21(e.target.value)}>
              <option hidden defaultValue>Elige la opción</option>
              <option value="Cumple">Cumple</option>
              <option value="No cumple">No cumple</option>
              <option value="No aplica">No aplica</option>
          </select> 
          <p>Se seca las manos con toalla de un solo uso</p>
          <select name="select" onChange={(e)=>setLimpieza22(e.target.value)}>
              <option hidden defaultValue>Elige la opción</option>
              <option value="Cumple">Cumple</option>
              <option value="No cumple">No cumple</option>
              <option value="No aplica">No aplica</option>
          </select> 
          <p>Utiliza la toalla para cerrar el grifo</p>
          <select name="select" onChange={(e)=>setLimpieza23(e.target.value)}>
              <option hidden defaultValue>Elige la opción</option>
              <option value="Cumple">Cumple</option>
              <option value="No cumple">No cumple</option>
              <option value="No aplica">No aplica</option>
          </select> 
          <Input
            label='Observaciones' 
            type="text" 
            onChange={(e)=>setObservacion(e.target.value)}
          />     
        <div className="form__btn">
            <Button
                title='Enviar'
            />
        </div>
        </form>
    </div>
   </div>
   <Navbar/>
   </>
  )
}

export default Higiene;